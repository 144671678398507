// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import "~@angular/material/theming";
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$chaka-console-primary: mat-palette($mat-light-blue, 100);
$chaka-console-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$chaka-console-warn: mat-palette($mat-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$chaka-console-theme: mat-light-theme(
  (
    color: (
      primary: $chaka-console-primary,
      accent: $chaka-console-accent,
      warn: $chaka-console-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($chaka-console-theme);

@import "https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700";

@import "https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700";
@import "~@ng-select/ng-select/themes/default.theme.css";
@import "~ngx-smart-modal/ngx-smart-modal";
@import "~@circlon/angular-tree-component/css/angular-tree-component.css";

.chaka-route-content {
  min-height: 60vh;
  max-height: 75vh;
  overflow: auto;
  // max-width: 95%;
}

.chaka-route-content::-webkit-scrollbar {
  width: 0.2em;
  background-color: transparent;
}

// .chaka-route-content::-webkit-scrollbar-track {
// }

.chaka-route-content::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.kt-margin-bottom-20 {
  margin-bottom: 20px;
}

.nsm-content {
  background-color: transparent !important;
  box-shadow: unset !important;
  width: 50vw;
}

.loading {
  color: white;
}

.chaka-table-header-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.btn {
  &.btn-primary {
    color: white !important;
  }

  &.btn-success {
    color: white !important;
  }
}

input.ng-invalid.ng-touched,
textarea.ng-invalid.ng-touched,
ng-select.ng-invalid.ng-touched,
.form-control.ng-invalid.ng-touched {
  border: 1px solid red;
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-typography h6,
.mat-typography h5 {
  font: unset;
}

.pretty-link {
  // text-decoration: none;
  cursor: pointer;
}

.pretty-link:hover {
  color: #4e73df !important;
}

.pointer {
  cursor: pointer;
}

.action-icon {
  padding: 0.5rem;
  cursor: pointer;
  border-radius: 50%;
}

.action-icon:hover {
  background-color: rgba(0, 0, 0, 0.08);
}

.paginate_button {
  cursor: pointer;
  background: #f0f3ff;
}

.ng-select {
  width: 100%;
}

.ng-select.ng-select-single .ng-select-container {
  border: 1px solid #e4e6ef;
}

.ng-select.ng-select-disabled > .ng-select-container {
  background-color: #f3f6f9;
}

.table-container {
  overflow: auto;
}

.no-data-container {
  height: 400px;
}

.no-data-img {
  width: 8rem;
}

.no-data-text {
  font-size: 14px;
  line-height: 2;
}

.no-pointer {
  pointer-events: none;
  cursor: not-allowed;
}

.cdk-overlay-pane {
  z-index: 1100 !important;
}

.cdk-overlay-container {
  z-index: 1100 !important;
}

//EDIT STYLES
.details-pane {
  width: 100%;
  padding: 0.3em;
}

.details-pane__content {
  margin: 0.7rem 0;
  padding: 0rem 0 0.6rem 0;
  width: 50%;
  float: left;
  font-size: 1em;
  color: rgb(65, 64, 64);
}

.details-pane__content > span {
  display: block;
}

@media (max-width: 992px) {
  .details-pane__content {
    width: 100%;
  }
}

//EDIT STYLES END

// for audit details code view
pre {
  background: black;
  font-weight: 400;
  word-break: break-all;

  .number-line {
    color: #adadaf;
  }
  .string {
    color: #95c602;
  }
  .number {
    color: #f2b619;
  }
  .boolean {
    color: #0097f1;
  }
  .null {
    color: #727990;
  }
  .key {
    color: #fff;
  }
}

tr.not-allowed-row {
  background-color: rgba(253, 199, 199, 0.3);
}

tr.not-allowed-row:hover {
  background-color: rgba(253, 199, 199, 0.4);
}

.expansion-card {
  margin-bottom: 2rem;
}

.expansion-card-title {
  margin-bottom: 2rem;
  font-size: 1.2rem;
}

.expansion-card-content {
  padding-bottom: 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  margin-bottom: 2rem;
}

.expansion-card-content:last-child {
  border-bottom: none;
  padding-bottom: 0;
  margin-bottom: 0;
}
